// Action Types
import {
  ADDRESS,
  ADD_ANNOUNCEMENT,
  ADD_APPROVER_RULE,
  ADD_EXPIRING_COMPANY_DOCUMENTS,
  ADD_HOLIDAY,
  ADD_SCHEDULES,
  ALL_EMPLOYEE_DOCUMENTS,
  ANNOUNCEMENTS,
  APPROVER_RULES,
  COMPANY,
  EDIT_APPROVER_RULE,
  EDIT_HOLIDAY,
  EDIT_VACATION,
  EMPLOYEE_DOCUMENTS,
  EXPIRING_COMPANY_DOCUMENTS,
  GET_ERRORS,
  POC,
  POC_ADDRESS,
  REMOVE_APPROVER_RULE,
  RESOURCES,
  SECTORS,
  DELETE_HOLIDAY,
} from "./types";
//Mutations
import {
  ADDRESS_UPDATE,
  APPROVER_RULE_DELETE,
  APPROVER_RULE_UPDATE,
  COMPANY_UPDATE,
  CREATE_ANNOUNCEMENT,
  CREATE_APPROVER_RULE,
  CREATE_SCHEDULE,
  POC_ADDRESS_UPDATE,
  POC_UPDATE,
  UPDATE_COMPANY_VACATION,
  UPDATE_OR_CREATE_OFFICIAL_HOLIDAY,
  DELETE_OFFICIAL_HOLIDAY,
} from "./Mutations";
//Queries
import {
  ANNOUNCEMENTS_QUERY,
  APPROVER_RULES_QUERY,
  COMPANY_QUERY,
  EMPLOYEE_DOCUMENTS_QUERY,
  EXPIRING_COMPANY_DOCUMENTS_QUERY,
  RESOURCES_QUERY,
  SECTORS_QUERY,
} from "./Queries";
//Actions
import {
  fetchDepartments,
  fetchDocuments,
  fetchEmployees,
  fetchLocations,
  fetchPositions,
} from "./index";
import { fetchDocumentTypes, fetchEmployeesWithPermissions } from "./employees";

// Graphql
import client from "./client";
import moment from "moment";

export const fetchSectors = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: SECTORS_QUERY,
      variables: null,
    });

    const sectors = res.data.sectors;
    dispatch({ type: SECTORS, payload: sectors });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchAnnouncements = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: ANNOUNCEMENTS_QUERY,
      variables: null,
    });

    const announcements = res.data.announcements;
    dispatch({ type: ANNOUNCEMENTS, payload: announcements });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchResources = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: RESOURCES_QUERY,
      variables: null,
    });

    const resources = res.data.resources;
    dispatch({ type: RESOURCES, payload: resources });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeDocuments =
  (data, type = "expiring") =>
  async (dispatch) => {
    try {
      const res = await client.query({
        query: EMPLOYEE_DOCUMENTS_QUERY,
        variables: data,
      });

      const documents = res.data.employeeDocuments;
      if (type === "all") {
        dispatch({ type: ALL_EMPLOYEE_DOCUMENTS, payload: documents });
      } else {
        dispatch({ type: EMPLOYEE_DOCUMENTS, payload: documents });
      }
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const fetchExpiringCompanyDocuments = (data) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EXPIRING_COMPANY_DOCUMENTS_QUERY,
      variables: data,
    });

    const documents = res.data.expiringCompanyDocuments;

    dispatch({ type: EXPIRING_COMPANY_DOCUMENTS, payload: documents });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchExpiringCompanyDocumentsThreeMonth =
  (data) => async (dispatch) => {
    try {
      const res = await client.query({
        query: EXPIRING_COMPANY_DOCUMENTS_QUERY,
        variables: data,
      });

      const documents = res.data.expiringCompanyDocuments;

      if (documents.length > 0) {
        dispatch({
          type: ADD_EXPIRING_COMPANY_DOCUMENTS,
          payload: documents,
        });
      }
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const fetchCompany = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: COMPANY_QUERY,
      variables: null,
    });

    const company = res.data.company;
    console.log("======== company ========", company);
    dispatch({ type: COMPANY, payload: company });
    dispatch(fetchDocuments());
    dispatch(fetchLocations());
    dispatch(fetchDepartments());
    dispatch(fetchPositions());
    dispatch(fetchEmployees());
    dispatch(fetchEmployeesWithPermissions());
    dispatch(fetchAnnouncements());
    dispatch(
      fetchExpiringCompanyDocumentsThreeMonth({
        exp: moment(new Date()).format("yyyy-MM-DD"),
      }),
    );
    dispatch(
      fetchExpiringCompanyDocumentsThreeMonth({
        exp: moment(new Date()).add(1, "months").format("yyyy-MM-DD"),
      }),
    );
    dispatch(
      fetchExpiringCompanyDocumentsThreeMonth({
        exp: moment(new Date()).add(2, "months").format("yyyy-MM-DD"),
      }),
    );
    dispatch(
      fetchExpiringCompanyDocumentsThreeMonth({
        exp: moment(new Date()).add(3, "months").format("yyyy-MM-DD"),
      }),
    );
    dispatch(fetchDocumentTypes());
    dispatch(fetchEmployeeDocuments(null, "all"));
    dispatch(
      fetchEmployeeDocuments({ exp: moment(new Date()).format("yyyy-MM-DD") }),
    );
    dispatch(fetchApproverRules());
    // if (company.expiringCompanyDocuments)
    //   dispatch({
    //     type: EXPIRING_COMPANY_DOCUMENTS,
    //     payload: company.expiringCompanyDocuments,
    //   });
    if (company.address) dispatch({ type: ADDRESS, payload: company.address });
    if (company.poc) dispatch({ type: POC, payload: company.poc });
    if (company.poc.address)
      dispatch({ type: POC_ADDRESS, payload: company.poc.address });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateCompanyProfile = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: COMPANY_UPDATE,
      variables: data,
    });

    const company = res.data.updateCompanyProfile.company;

    dispatch({ type: COMPANY, payload: company });
    if (company.address) dispatch({ type: ADDRESS, payload: company.address });

    // history.push("/");
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateCompanyVacation = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_COMPANY_VACATION,
      variables: data,
    });

    const vacation = res.data.updateCompanyVacation.companyVacation;

    dispatch({ type: EDIT_VACATION, payload: vacation });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateOrCreateOfficialHoliday =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_OR_CREATE_OFFICIAL_HOLIDAY,
        variables: data,
      });

      const holiday = res.data.updateOrCreateOfficialHoliday.holiday;
      if (data.id) {
        dispatch({ type: EDIT_HOLIDAY, payload: holiday });
      } else {
        dispatch({ type: ADD_HOLIDAY, payload: holiday });
      }
      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };
export const deleteOfficialHoliday = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: DELETE_OFFICIAL_HOLIDAY,
      variables: data,
    });

    const status = res.data.deleteOfficialHoliday.status;
    if (status) dispatch({ type: DELETE_HOLIDAY, payload: data });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateCompanyAddress = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: ADDRESS_UPDATE,
      variables: data,
    });
    const address = res.data.updateCompanyAddress.address;

    dispatch({ type: ADDRESS, payload: address });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updatePoc = (data, address, history) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: POC_UPDATE,
      variables: data,
    });
    const poc = res.data.updatePoc.poc;
    if (address && poc)
      dispatch(updatePocAddress({ ...address, pocAddress: true }, history));
    dispatch({ type: POC, payload: poc });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updatePocAddress = (data, history) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: POC_ADDRESS_UPDATE,
      variables: data,
    });
    const address = res.data.updateCompanyAddress.address;
    if (address && history) history.replace("/");

    dispatch({ type: POC_ADDRESS, payload: address });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createAnnouncement = (data, close) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_ANNOUNCEMENT,
      variables: data,
    });

    const announcement = res.data.createAnnouncement.announcement;
    if (close) close();

    dispatch({ type: ADD_ANNOUNCEMENT, payload: announcement });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createCompanySchedule = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_SCHEDULE,
      variables: data,
    });

    const schedules = res.data.createOrUpdateCompanySchedules.companySchedules;

    dispatch({ type: ADD_SCHEDULES, payload: schedules });
    dispatch(fetchCompany());
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchApproverRules = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: APPROVER_RULES_QUERY,
      variables: null,
    });

    const approverRules = res.data.requestsApprovers;
    dispatch({ type: APPROVER_RULES, payload: approverRules });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const addApproverRule = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: CREATE_APPROVER_RULE,
      variables: data,
    });

    const resApprover = res.data.addRequestApproverRule.approverRule;

    dispatch({ type: ADD_APPROVER_RULE, payload: resApprover });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const deleteApproverRule = (approverRuleId) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: APPROVER_RULE_DELETE,
      variables: approverRuleId,
    });

    const status = res.data.removeRequestApproverRule.status;
    if (status)
      dispatch({ type: REMOVE_APPROVER_RULE, payload: approverRuleId });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateApproverRule = (approverRule) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: APPROVER_RULE_UPDATE,
      variables: approverRule,
    });

    const updatedApproverRule = res.data.updateRequestApproverRule.approverRule;

    dispatch({ type: EDIT_APPROVER_RULE, payload: updatedApproverRule });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
