import { Box, Typography, useMediaQuery } from "@material-ui/core";

import { DonutChart } from "../../../common";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const VacationBalanceItem = ({ label, value }) => {
  const {
    i18n: { language },
  } = useTranslation("common");

  const isEnglish = language === "en";

  return (
    <Box display="flex" paddingY={1} gridGap={10} marginX={1}>
      <Typography
        color="secondary"
        style={{ fontWeight: "bolder" }}
        align={isEnglish ? "left" : "right"}
      >
        {" "}
        {label}
      </Typography>
      <Typography
        color="secondary"
        style={{ fontWeight: "bolder" }}
        align={isEnglish ? "left" : "right"}
      >
        {value}
      </Typography>
    </Box>
  );
};

const VacationsOverview = ({ employee, user }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("common");

  const isEnglish = language === "en";

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const availableBalance = employee.leaveBalance.annualBalance;

  const vaacationTypes = useSelector((state) => state.company.vacationTypes);

  const vacationBalances = Object.keys(employee.leaveBalance)
    .filter(
      (balance) =>
        vaacationTypes.includes(balance.split("Balance")[0].toUpperCase()) &&
        balance !== "annualBalanceUncapped",
    )
    .map((balance) => (
      <VacationBalanceItem
        key={"balance" + balance}
        label={t(`common:${balance.split("Balance")[0].toLowerCase()}`)}
        value={employee.leaveBalance[balance]}
      />
    ));
  // let burned = employee.leaveBalance.annualBalanceUncapped;

  let usedBalance = 0;

  employee.leaveBalance.leavedebits.forEach((debit) => {
    if (debit.isCounted && debit.kind === "ANNUAL") usedBalance += debit.amount;
  });

  const getLabels = () => {
    if (availableBalance === 0 && usedBalance === 0) {
      return [];
    } else {
      return [
        {
          label: t("availableCredit"),
          value: availableBalance,
        },
        {
          label: t("usedCredit"),
          value: usedBalance,
        },
      ];
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={lgUp ? "row" : "column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box>
        <DonutChart
          label={({ dataEntry }) =>
            `${dataEntry.title}  (${dataEntry.value} ${t("days")})`
          }
          radius={35}
          labels={getLabels()}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={lgUp ? "column" : "row"}
        marginLeft={lgUp && isEnglish ? 10 : !isEnglish ? 0 : 0}
        marginRight={lgUp && !isEnglish ? 10 : isEnglish ? 0 : 0}
      >
        {vacationBalances}

        {/* <VacationBalanceItem label={t("common:burned")} value={burned} /> */}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(VacationsOverview);
