import HomeCard from "../../common/HomeCard";
import { Link } from "react-router-dom";
import React from "react";
import { RequestTableCell } from ".";
import StyledHomeTable from "../../common/StyledHomeTable";
import StyledHomeTableHeader from "../../common/StyledHomeTableHeader";
import { TableRow } from "@material-ui/core";
// import { isEmployeeOnVacation } from "../../../helpers/isEmployeeOnVacation";
// import { isLeaveRequestApproved } from "../../../helpers/isLeaveRequestApproved";
// import { normalizeLeaveRequest } from "../../../helpers/normalizeLeaveRequest";
// import { sortByNewest } from "../../../helpers/sortByNewest";
import { useTranslation } from "react-i18next";
import moment from "moment";

const EmployeesOnVacation = ({ requests, employees }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["home", "common"]);

  const isEnglish = language === "en";

  const labels = [
    t("common:employeeName"),
    t("common:startDate"),
    t("common:endDate"),
  ];

  const header = labels.map((label, i) => (
    <StyledHomeTableHeader label={label} key={i} />
  ));

  // set end date if leave debit has been created with amount
  const setEndDate = (_leave) => {
    let endDate = null;
    if (
      moment(_leave.endDate, "dd-mm-yyyy").isSame(
        moment(_leave.date, "dd-mm-yyyy"),
      ) &&
      Number(_leave.amount) >= 1
    ) {
      endDate = moment(_leave.date).add(_leave.amount - 1, "days");
    } else {
      endDate = moment(_leave.endDate);
    }
    return endDate;
  };

  // check if employee is on vacation today
  const isEmployeeOnVacation = (_leave) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const compareDate = moment(today, "YYYY-MM-DD");
    const endDate = setEndDate(_leave);
    const isBetween = compareDate.isBetween(
      _leave.date,
      endDate ? endDate : _leave.endDate,
      "days",
      "[]",
    );
    return isBetween;
  };

  // sort leave debits by start date
  const sortLeaveDebits = (leave, nextLeave) => {
    const aDate = moment(leave.startDate, "DD-MM-YYYY");
    const bDate = moment(nextLeave.startDate, "DD-MM-YYYY");
    if (aDate.isBefore(bDate)) {
      return -1;
    } else if (aDate.isAfter(bDate)) {
      return 1;
    } else {
      return 0;
    }
  };

  // const data = requests
  //   .filter((request) => {
  //     if (isLeaveRequestApproved(request) && isEmployeeOnVacation(request)) {
  //       return request;
  //     }
  //     return false;
  //   })

  //   .map((request) => normalizeLeaveRequest(request))
  //   .sort((currentReq, nextRequest) => sortByNewest(currentReq, nextRequest));

  // extract data from employees leave balance
  const laeveData = employees
    .map((employee) => {
      return employee.leaveBalance.leavedebits
        .filter(
          (leave) =>
            leave.kind !== "SHORT" &&
            isEmployeeOnVacation(leave) &&
            !leave.balanceAdjustment,
        )
        .map((leave) => {
          const leaveEndDate = setEndDate(leave);
          return {
            id: employee.id,
            name: employee.fullName,
            startDate: moment(leave.date).format("DD-MM-yyyy"),
            endDate: leaveEndDate.format("DD-MM-yyyy"),
            amount: leave.amount,
          };
        });
    })
    .flat(1)
    .sort((leave, nextleave) => sortLeaveDebits(leave, nextleave));

  const rows = laeveData.map((entry, i) => {
    return (
      <TableRow
        component={Link}
        to={`employees/${entry.id}?tab=leave`}
        key={(entry.id, +i)}
        align={isEnglish ? "left" : "right"}
        style={{ textDecoration: "none" }}
      >
        <RequestTableCell colSpan={4} align={isEnglish ? "left" : "right"}>
          {entry.name}
        </RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.startDate}</RequestTableCell>
        <RequestTableCell colSpan={4}>{entry.endDate}</RequestTableCell>
      </TableRow>
    );
  });
  return (
    <HomeCard
      title={t("home:employeesOnVacation")}
      navigationAction={"/employeesList"}
    >
      <StyledHomeTable header={header} body={rows} data={laeveData} />
    </HomeCard>
  );
};

export default EmployeesOnVacation;
