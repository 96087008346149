import { DetailsItem } from "../../../../common";
import { Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const Details = ({ vacation }) => {
  const { t } = useTranslation(["common", "companyProfile"]);

  // const leaveDetails = [
  //   { label: t("companyProfile:annualLeave"), value: vacation?.annualLeave },
  //   {
  //     label: t("companyProfile:accumulatedFor"),
  //     value: vacation?.accumulatedFor,
  //   },
  //   { label: t("companyProfile:sickLeave"), value: vacation?.sickLeave },
  //   { label: t("companyProfile:casualLeave"), value: vacation?.casualLeave },
  //   { label: t("companyProfile:offsetLeave"), value: vacation?.offsetLeave },
  //   { label: t("companyProfile:shortLeave"), value: vacation?.shortLeave },
  //   {
  //     label: t("companyProfile:wholeBalance"),
  //     value: vacation?.wholeBalance ? "Enabled" : "Disabled",
  //   },
  // ];
  const labels = Object.keys(vacation).filter((item) =>
    item.includes("Active"),
  );

  const leaveDetails = labels
    .map((label) => {
      if (vacation[label] === true) {
        return {
          label: t(`companyProfile:${label.split("Active")[0]}`),
          value: vacation[label.split("Active")[0]],
        };
      } else {
        return null;
      }
    })
    .filter((detail) => detail !== null);

  leaveDetails.push({
    label: t("companyProfile:wholeBalance"),
    value: vacation.wholeBalance ? t("common:enabled") : t("common:disabled"),
  });

  return leaveDetails?.map((detail, idx) => {
    return (
      <Grid item md={6} key={idx}>
        <DetailsItem label={detail.label} value={detail.value} />
      </Grid>
    );
  });
};

export default Details;
