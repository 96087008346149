import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
} from "@material-ui/core";
import React, { useState } from "react";

import Details from "./Details";
import { Edit } from "@material-ui/icons";
import Form from "./Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const LeaveDetails = ({ updateCompanyVacation, vacation, vacationTypes }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "companyProfile"]);
  const isEnglish = language === "en";
  const [edit, setEdit] = useState(false);
  console.log("vacationTypes", vacationTypes);
  return (
    <Card dir={isEnglish ? "ltr" : "rtl"}>
      <CardHeader
        title={t("companyProfile:leaveDetails")}
        action={
          !edit && (
            <IconButton onClick={() => setEdit(true)}>
              <Edit color="primary" />
            </IconButton>
          )
        }
      />

      {edit ? (
        <CardContent style={{ direction: isEnglish ? "ltr" : "rtl" }}>
          <Form vacation={vacation} edit={edit} setEdit={setEdit} />
        </CardContent>
      ) : (
        <List disablepadding>
          <Grid container spacing={3} style={{ padding: 10 }}>
            <Details vacation={vacation} />
          </Grid>
        </List>
      )}
    </Card>
  );
};

const mapStateToProps = ({ company }) => ({
  vacation: company.vacation,
  vacationTypes: company.vacationTypes,
});

export default connect(mapStateToProps)(LeaveDetails);
