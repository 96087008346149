// Graphql
import client from "./client";
//Mutations
import {
  CREATE_RECURRING_SALARY_MODIFICATION,
  CREATE_SALARY_MODIFICATION,
  RECURRING_SALARY_MODIFICATION_DELETE,
  SALARY_DEPOSIT,
  SALARY_MODIFICATION_DELETE,
  UPDATE_EMPLOYEE_HR_ACCESS,
  UPDATE_PERMISSION_EMPLOYEE,
  UPDATE_RECURRING_SALARY_MODIFICATION,
  UPDATE_SALARY_MODIFICATION,
} from "./Mutations";
//Queries
import {
  DOCUMENT_TYPES_QUERY,
  EMPLOYEES_LEAVE_BALANCE_QUERY,
  EMPLOYEES_NEXT_SALARY_DEPOSIT_QUERY,
  EMPLOYEES_PAYROLL_QUERY,
  EMPLOYEES_QUERY,
  EMPLOYEES_WITH_HR_ACCESS_QUERY,
  EMPLOYEES_WITH_PERMISSIONS_QUERY,
  EMPLOYEE_ATTENDANCES_QUERY,
  EMPLOYEE_DETAILS_QUERY,
  EMPLOYEE_INDEMNITY,
  EMPLOYEE_NEXT_SALARY_DEPOSIT_QUERY,
  EMPLOYEE_SALARY_DEPOSITS_QUERY,
  HYPOTHETICAL_INDEMNITY,
  EMPLOYEES_TALLIES_QUERY,
  PAYROLL_PAGE_QUERY,
} from "./Queries";
// Action Types
import {
  ADD_EMPLOYEES_MUTLIPLE_ATTENDANCES,
  ADD_EMPLOYEES_MUTLIPLE_PAYROLL,
  ADD_PERMISSION_EMPLOYEE,
  ADD_RECURRING_SALARY_MODIFICATION,
  ADD_SALARY_MODIFICATION,
  DELETE_PERMISSION_EMPLOYEE,
  DELETE_RECURRING_SALARY_MODIFICATION,
  DELETE_SALARY_MODIFICATION,
  DEPOSITS,
  DOCUMENT_TYPES,
  EDIT_EMPLOYEE_HR_ACCESS,
  EDIT_PERMISSION_EMPLOYEE,
  EDIT_RECURRING_SALARY_MODIFICATION,
  EDIT_SALARY_MODIFICATION,
  EMPLOYEE,
  EMPLOYEES,
  EMPLOYEES_LEAVE_BALANCE,
  EMPLOYEES_PAYROLL,
  EMPLOYEES_WITH_HR_ACCESS,
  EMPLOYEE_ATTENDANCES,
  EMPLOYEE_DEPOSITS,
  EMPLOYEE_LOADING,
  EMPLOYEE_NEXT_DEPOSIT,
  EMPLOYEE_OVERTIME_CREDITS,
  FETCH_EMPLOYEES_MUTLIPLE_ATTENDANCES_LOADING,
  FETCH_EMPLOYEES_MUTLIPLE_PAYROLL_LOADING,
  GET_ERRORS,
  HYPOTHETICAL_INDEMNITIES,
  INDEMNITY,
  NEXT_DEPOSIT,
  PERMISSION_EMPLOYEES,
  EMPLOYEES_TALLIES,
} from "./types";

export const fetchDocumentTypes = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: DOCUMENT_TYPES_QUERY,
      variables: null,
    });
    const types = res.data.documentTypes;
    dispatch({ type: DOCUMENT_TYPES, payload: types });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployees = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_QUERY,
      variables: null,
    });

    const employees = res.data.employees;
    dispatch({ type: EMPLOYEES, payload: employees });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeesLeaveBalance = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_LEAVE_BALANCE_QUERY,
      variables: null,
    });
    const employees = res.data.employees;
    dispatch({ type: EMPLOYEES_LEAVE_BALANCE, payload: employees });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeesPayroll = (month) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_PAYROLL_QUERY,
      variables: month,
    });

    const employees = res.data.employeesPayroll;
    dispatch({ type: EMPLOYEES_PAYROLL, payload: employees });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchPayrollPage = (month) => async (dispatch) => {
  try {
    const res = await client.query({
      query: PAYROLL_PAGE_QUERY,
      variables: month,
    });

    const employees = res.data.employeesPayroll;
    dispatch({ type: EMPLOYEES_PAYROLL, payload: employees });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeesMutliplePayrollLoading =
  (status) => async (dispatch) => {
    dispatch({
      type: FETCH_EMPLOYEES_MUTLIPLE_PAYROLL_LOADING,
      payload: status,
    });
  };

export const fetchEmployeesMutliplePayroll =
  (monthRange) => async (dispatch) => {
    dispatch(fetchEmployeesMutliplePayrollLoading(true));
    try {
      const res = await client.query({
        query: EMPLOYEES_PAYROLL_QUERY,
        variables: monthRange,
      });

      const payroll = res.data.employeesPayroll;

      if (payroll.length > 0) {
        dispatch({
          type: ADD_EMPLOYEES_MUTLIPLE_PAYROLL,
          payload: payroll,
        });
      }
    } catch (error) {
      dispatch(fetchEmployeesMutliplePayrollLoading(false));
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const fetchMutliplePayroll = (monthRange) => async (dispatch) => {
  dispatch(fetchEmployeesMutliplePayrollLoading(true));
  try {
    const res = await client.query({
      query: PAYROLL_PAGE_QUERY,
      variables: monthRange,
    });

    const payroll = res.data.employeesPayroll;

    if (payroll.length > 0) {
      dispatch({
        type: ADD_EMPLOYEES_MUTLIPLE_PAYROLL,
        payload: payroll,
      });
    }
  } catch (error) {
    dispatch(fetchEmployeesMutliplePayrollLoading(false));
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeesWithHrAccess = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_WITH_HR_ACCESS_QUERY,
      variables: null,
    });

    const employees = res.data.hrEmployees;
    dispatch({ type: EMPLOYEES_WITH_HR_ACCESS, payload: employees });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeesWithPermissions = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_WITH_PERMISSIONS_QUERY,
      variables: null,
    });

    const employees = res.data.employeesWithAccess;

    const employeesWithPermissions = employees.map((employee) => {
      return {
        id: employee.id,
        fullName: employee?.fullName,
        hasDocumentAccess: employee.user.hasDocumentAccess,
        hasIndemnityAccess: employee.user.hasIndemnityAccess,
        hasVacationsAccess: employee.user.hasVacationsAccess,
        hasAttendanceAccess: employee.user.hasAttendanceAccess,
        locations: employee.managedLocations,
        departments: employee.managedDepartments,
      };
    });
    dispatch({ type: PERMISSION_EMPLOYEES, payload: employeesWithPermissions });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

//Will i need a function to update eache individual employee and a function to update the hrAcces Array?

export const updateHrAccess = (data) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_EMPLOYEE_HR_ACCESS,
      variables: data,
    });
    const result = res.data.updateHrAccess.result;
    if (result) {
      dispatch({ type: EDIT_EMPLOYEE_HR_ACCESS, payload: data });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

//   const employeesWithAccess = fetchEmployeesWithAccess.data.hrEmployees;
//   const employeesIdsWithAccess = employeesWithAccess.map(({ id }) => id);

//   let newEmployees = [];

//   try {
//   employeesIds.forEach((id) => {
//       if (!employeesIdsWithAccess.includes(id)) {
//         const grantEmployeeAcess = client.mutate({
//           mutation: UPDATE_EMPLOYEE_HR_ACCESS,
//           variables: { id, status: 1 },
//         });

//         if (grantEmployeeAcess.data.hrAccess.result) {
//           newEmployees.push(id);
//         }
//       } else {
//         newEmployees.push(id);
//       }
//     });

//     employeesIdsWithAccess.forEach((id) => {
//       if  (!employeesIds.includes(id)) {
//         client.mutate({
//           mutation: UPDATE_EMPLOYEE_HR_ACCESS,
//           variables: { id, status: 0 },
//         });
//       }
//     });

//     dispatch({ type: EDIT_EMPLOYEES_HR_ACCESS, payload: newEmployees });
//   } catch (error) {
//     if (error.response) {
//       dispatch({
//         type: GET_ERRORS,
//         errors: error.response.data,
//       });
//     } else {
//       console.error(error);
//     }
//   }
// };

// export const updateHrAccess =
//   ({ previousEmployees, newEmployees }) =>
//   async (dispatch) => {
//     const employeeIdsAdded = [...previousEmployees].filter(
//       (x) => !newEmployees.has(x)
//     );

//     const employeeIdsRemoved = [...newEmployees].filter(
//       (x) => !previousEmployees.has(x)
//     );

//     try {
//       employeeIdsAdded.forEach(async (employeeId) => {
//         await client.mutate({
//           mutation: EDIT_EMPLOYEES_HR_ACCESS,
//           variables: { employeeId, status: 1 },
//         });
//       });

//       employeeIdsRemoved.forEach(async (employeeId) => {
//         await client.mutate({
//           mutation: EDIT_EMPLOYEES_HR_ACCESS,
//           variables: { employeeId, status: 0 },
//         });
//       });

//       dispatch({
//         type: EDIT_EMPLOYEES_HR_ACCESS,
//         payload: [...newEmployees],
//       });
//     } catch (error) {
//       if (error.response) {
//         dispatch({
//           type: GET_ERRORS,
//           errors: error.response.data,
//         });
//       } else {
//         console.error(error);
//       }
//     }
//   };

export const employeeLoading = () => async (dispatch) => {
  dispatch({ type: EMPLOYEE_LOADING });
};

export const fetchEmployeeDetails = (employeeId) => async (dispatch) => {
  try {
    dispatch(employeeLoading());
    const res = await client.query({
      query: EMPLOYEE_DETAILS_QUERY,
      variables: employeeId,
    });

    const employee = res.data.employee;
    dispatch({ type: EMPLOYEE, payload: employee });
    dispatch({
      type: EMPLOYEE_OVERTIME_CREDITS,
      payload: employee.overtimeCredits,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeDeposits = (employeeId) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEE_SALARY_DEPOSITS_QUERY,
      variables: employeeId,
    });

    console.log("====================================");
    console.log("<<<<<>>>>>", res.data.salaryDeposit);
    console.log("====================================");

    const salaryDeposit = res.data.salaryDeposit;
    if (employeeId) {
      dispatch({ type: DEPOSITS, payload: salaryDeposit });
    } else {
      dispatch({ type: EMPLOYEE_DEPOSITS, payload: salaryDeposit });
    }
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeNextDeposit = (data) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEE_NEXT_SALARY_DEPOSIT_QUERY,
      variables: data,
    });

    const nextSalaryDeposit = res.data.nextSalaryDeposit;
    console.log("====================================res", res);
    console.log("====================================nextSalaryDeposit", data);
    if (data.employeeId) {
      dispatch({ type: NEXT_DEPOSIT, payload: nextSalaryDeposit });
    } else {
      dispatch({ type: EMPLOYEE_NEXT_DEPOSIT, payload: nextSalaryDeposit });
    }
    console.log("nextSalaryDeposit ======", nextSalaryDeposit);
    return nextSalaryDeposit;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
export const fetchEmployeesNextDeposit = (data) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_NEXT_SALARY_DEPOSIT_QUERY,
      variables: data,
    });

    const nextSalaryDeposit = res.data.employeesNextSalaryDeposit;
    dispatch({ type: EMPLOYEE_NEXT_DEPOSIT, payload: nextSalaryDeposit });
    return nextSalaryDeposit;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeIndemnity = (data) => async (dispatch) => {
  dispatch({ type: INDEMNITY, payload: null });
  try {
    const res = await client.query({
      query: EMPLOYEE_INDEMNITY,
      variables: data,
    });

    const indemnity = res.data.hypotheticalIndemnity;

    dispatch({ type: INDEMNITY, payload: indemnity });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createDeposit = (data, closeModal) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: SALARY_DEPOSIT,
      variables: data,
    });
    if (closeModal) closeModal();
    if (data.employeeIds.length === 1) {
      const employeeId = data.employeeIds[0];
      dispatch(fetchEmployeeDeposits({ employeeId }));
      dispatch(fetchEmployeeNextDeposit({ employeeId }));
    }

    return res.data.depositSalaries;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createSalaryModification =
  (data, setClose) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: CREATE_SALARY_MODIFICATION,
        variables: data,
      });
      if (setClose) setClose();

      const salaryModification =
        res.data.updateOrCreateSalaryModification.salaryModification;

      dispatch({ type: ADD_SALARY_MODIFICATION, payload: salaryModification });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateSalaryModification =
  (data, setClose) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_SALARY_MODIFICATION,
        variables: data,
      });
      if (setClose) setClose();
      const salaryModification =
        res.data.updateOrCreateSalaryModification.salaryModification;

      dispatch({ type: EDIT_SALARY_MODIFICATION, payload: salaryModification });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const deleteSalaryModification = (id, setClose) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: SALARY_MODIFICATION_DELETE,
      variables: id,
    });
    if (setClose) setClose();

    const status = res.data.deleteSalaryModification.status;
    if (status) dispatch({ type: DELETE_SALARY_MODIFICATION, payload: id });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createRecurringSalaryModification =
  (data, setClose) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: CREATE_RECURRING_SALARY_MODIFICATION,
        variables: data,
      });
      if (setClose) setClose();
      const recurringSalaryModification =
        res.data.updateOrCreateRecurringSalaryModification
          .recurringSalaryModification;

      dispatch({
        type: ADD_RECURRING_SALARY_MODIFICATION,
        payload: recurringSalaryModification,
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateRecurringSalaryModification =
  (data, setClose) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_RECURRING_SALARY_MODIFICATION,
        variables: data,
      });
      if (setClose) setClose();
      const recurringSalaryModification =
        res.data.updateOrCreateRecurringSalaryModification
          .recurringSalaryModification;

      dispatch({
        type: EDIT_RECURRING_SALARY_MODIFICATION,
        payload: { ...recurringSalaryModification, oldId: data.id },
      });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const deleteRecurringSalaryModification =
  (id, setClose) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: RECURRING_SALARY_MODIFICATION_DELETE,
        variables: id,
      });
      if (setClose) setClose();

      const status = res.data.deleteRecurringSalaryModification.status;
      if (status)
        dispatch({ type: DELETE_RECURRING_SALARY_MODIFICATION, payload: id });
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const fetchHypotheticalIndemnities = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: HYPOTHETICAL_INDEMNITY,
      variables: null,
    });

    const hypotheticalIndemnities = res.data.hypotheticalIndemnities;

    dispatch({
      type: HYPOTHETICAL_INDEMNITIES,
      payload: hypotheticalIndemnities,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeAttendances = (data) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEE_ATTENDANCES_QUERY,
      variables: data,
    });

    const employeeAttendances = res.data.employeeAttendances;

    dispatch({
      type: EMPLOYEE_ATTENDANCES,
      payload: employeeAttendances,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeesMutlipleAttendancesLoading =
  (status) => async (dispatch) => {
    dispatch({
      type: FETCH_EMPLOYEES_MUTLIPLE_ATTENDANCES_LOADING,
      payload: status,
    });
  };

export const fetchEmployeesMutlipleAttendances = (data) => async (dispatch) => {
  dispatch(fetchEmployeesMutlipleAttendancesLoading(true));
  try {
    const res = await client.query({
      query: EMPLOYEE_ATTENDANCES_QUERY,
      variables: data,
    });

    const attendances = res.data.employeeAttendances;

    if (attendances.length > 0) {
      dispatch({
        type: ADD_EMPLOYEES_MUTLIPLE_ATTENDANCES,
        payload: attendances,
      });
    }
    return attendances;
  } catch (error) {
    dispatch(fetchEmployeesMutlipleAttendancesLoading(false));
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const createEmployeePortalPermission =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_PERMISSION_EMPLOYEE,
        variables: data,
      });
      const employee = res.data.updatePermissions.employee;
      const newEmployee = {
        id: employee.id,
        fullName: employee?.fullName,
        hasDocumentAccess: employee.user.hasDocumentAccess,
        hasIndemnityAccess: employee.user.hasIndemnityAccess,
        hasVacationsAccess: employee.user.hasVacationsAccess,
        hasAttendanceAccess: employee.user.hasAttendanceAccess,
        locations: employee.managedLocations,
        departments: employee.managedDepartments,
      };
      dispatch({ type: ADD_PERMISSION_EMPLOYEE, payload: newEmployee });
      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateEmployeePortalPermission =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_PERMISSION_EMPLOYEE,
        variables: data,
      });
      const employee = res.data.updatePermissions.employee;
      const updatedEmployee = {
        id: employee.id,
        fullName: employee?.fullName,
        hasDocumentAccess: employee.user.hasDocumentAccess,
        hasIndemnityAccess: employee.user.hasIndemnityAccess,
        hasVacationsAccess: employee.user.hasVacationsAccess,
        hasAttendanceAccess: employee.user.hasAttendanceAccess,
        locations: employee.managedLocations,
        departments: employee.managedDepartments,
      };
      dispatch({ type: EDIT_PERMISSION_EMPLOYEE, payload: updatedEmployee });
      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const deleteEmployeePortalPermission =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_PERMISSION_EMPLOYEE,
        variables: data,
      });
      const employeeToRemove = res.data.updatePermissions.employee.id;

      dispatch({ type: DELETE_PERMISSION_EMPLOYEE, payload: employeeToRemove });
      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const fetchEmployeesTallies = (data) => async (dispatch) => {
  try {
    const res = await client.query({
      query: EMPLOYEES_TALLIES_QUERY,
      variables: data,
    });

    const employeesTallies = res.data.employeesTallies;

    dispatch({
      type: EMPLOYEES_TALLIES,
      payload: employeesTallies,
    });

    return employeesTallies;
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
