import { Checkbox, Grid, Typography } from "@material-ui/core";
import LeaveFile from "../../../../../components/common/LeaveFile";
import moment from "moment";
import {
  DatePickerField,
  FormInputField,
  FormSelectInput,
} from "../../../../../components/common";
import TimePickerField from "../../../../../components/common/TimePickerField";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { leavesKind } from "../../../../../components/common/DropDownsData";
import { useSelector } from "react-redux";

export const VacationsForm = ({
  data,
  idx,
  uploadData,
  setData,
  setDisabled,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "upload"]);
  const isEnglish = language === "en";
  const [vacationsData, setVacationsData] = useState(data);
  const [error, setError] = useState("");
  const type = vacationsData.type.toLowerCase();
  const companyVacationTypes = useSelector(
    (state) => state.company.vacationTypes,
  );
  let leaveKinds =
    type !== "credit"
      ? leavesKind
      : leavesKind.filter((kind) => {
          return kind.value !== "UNPAID" && kind.value !== "SHORT";
        });

  // handle change in input fields
  const handleChange = (field, value) => {
    setVacationsData({ ...vacationsData, [field]: value });
  };
  useEffect(
    () => {
      if (!vacationsData.amount) {
        setVacationsData({ ...vacationsData, amount: 0 });
      }
    },
    // eslint-disable-next-line
    [],
  );
  // validate data and set errors
  useEffect(() => {
    let errors = {};
    // validation
    if (!vacationsData.id) {
      errors.id = t("upload:idError");
    }
    if (!vacationsData.reason) {
      errors.reason = t("upload:reasonError");
    }
    if (!vacationsData.kind) {
      errors.kind = t("upload:kindError");
    }
    if (vacationsData.kind === "SHORT") {
      if (!vacationsData.start_time) {
        errors.start_time = t("upload:startTimeError");
      }
      if (!vacationsData.end_time) {
        errors.end_time = t("upload:endTimeError");
      }
      if (!vacationsData.date) {
        errors.short_date = t("upload:dateError");
      }
    }
    if (vacationsData.type === "credit" && !vacationsData.amount) {
      errors.amount_credit = t("upload:amountError");
    }
    if (vacationsData.type === "credit" && !vacationsData.date) {
      errors.date_credit = t("upload:dateError");
    }
    if (
      vacationsData.type !== "credit" &&
      vacationsData.kind !== "SHORT" &&
      ((Number(vacationsData.amount) === 0 &&
        !vacationsData.date &&
        !vacationsData.end_date) ||
        (Number(vacationsData.amount) !== 0 &&
          vacationsData.date &&
          vacationsData.end_date))
    ) {
      errors.amount_dates = t("upload:amountOrDateError");
    }
    if (vacationsData.type !== "credit" && vacationsData.kind !== "SHORT") {
      if (!vacationsData.start_date && vacationsData.end_date) {
        errors.start_date = t("upload:dateError");
      }
      if (!vacationsData.end_date && vacationsData.start_date) {
        errors.end_date = t("upload:endDateError");
      }
      if (Number(vacationsData.amount) === 0 && vacationsData.date) {
        errors.amount = t("upload:amountError");
      }
      if (!vacationsData.date && Number(vacationsData.amount) !== 0) {
        errors.date = t("upload:dateError");
      }
    }
    setError(errors);

    // refine data to match each type
    if (vacationsData.type !== "credit" && vacationsData.kind !== "SHORT") {
      if (!vacationsData.hasOwnProperty("balance_adjustment")) {
        setVacationsData({ ...vacationsData, balance_adjustment: false });
      }
    } else {
      if (vacationsData.hasOwnProperty("balance_adjustment")) {
        delete vacationsData.balance_adjustment;
      }
    }

    if (vacationsData.kind === "SHORT") {
      if (vacationsData.hasOwnProperty("amount")) {
        delete vacationsData.amount;
      }
      if (vacationsData.hasOwnProperty("end_date")) {
        delete vacationsData.end_date;
      }
      if (vacationsData.hasOwnProperty("start_date")) {
        delete vacationsData.start_date;
      }
    }

    if (vacationsData.type === "credit") {
      if (vacationsData.hasOwnProperty("start_date")) {
        delete vacationsData.start_date;
      }
      if (vacationsData.hasOwnProperty("end_date")) {
        delete vacationsData.end_date;
      }
    }

    // set data to parent component if no errors

    let temp = uploadData;
    temp[idx] = vacationsData;
    setData(temp);

    // eslint-disable-next-line
  }, [data, vacationsData, setData, uploadData, idx]);

  // disable Submit button if there are errors

  if (Object.keys(error).length > 0) {
    setDisabled(true);
  } else {
    setDisabled(false);
  }

  return (
    <Grid container spacing={1}>
      <Grid container justifyContent="center" item xs={12} lg={12} md={12}>
        <Grid>
          <Typography variant="h3" color="primary">
            {" "}
            {t(`upload:${type}`)}{" "}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item md={11} xs={11} lg={11}>
          <FormInputField
            isRequired
            label={t("fullName")}
            name="fullName"
            type="text"
            onChange={(e) => {
              handleChange("full_name", e.target.value);
            }}
            value={vacationsData.full_name ? vacationsData.full_name : ""}
            id="fullName"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item md={11} xs={11} lg={11}>
          <FormInputField
            isRequired
            errors={error && error.id}
            label={t("id")}
            name="id"
            type="text"
            onChange={(e) => {
              handleChange("id", e.target.value);
            }}
            value={vacationsData.id}
            id="fullName"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item md={11} xs={11} lg={11}>
          <FormInputField
            isRequired
            errors={error && error.reason}
            label={t("reason")}
            name="reason"
            type="text"
            onChange={(e) => {
              handleChange("reason", e.target.value);
            }}
            value={vacationsData.reason}
            id="reason"
            autoComplete="off"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
        <Grid item md={11} xs={11}>
          <FormSelectInput
            isRequired
            errors={error && error.kind}
            label={t("common:leaveKind")}
            name="kind"
            selectOptions={leaveKinds
              .filter((kind) => companyVacationTypes.includes(kind.value))
              .map((kind) => ({
                label: isEnglish ? kind.name : kind.nameAr,
                value: kind.value,
              }))}
            onChange={(e) => {
              handleChange("kind", e);
            }}
            value={
              leaveKinds.find((kind) =>
                kind.value
                  .toLowerCase()
                  .slice(0, 3)
                  .includes(vacationsData.kind.toLowerCase().slice(0, 3)),
              )?.value
            }
          />
        </Grid>
      </Grid>

      {vacationsData.kind !== "SHORT" ? (
        type !== "credit" ? (
          <>
            <Grid
              container
              justifyContent="center"
              item
              xs={12}
              lg={12}
              md={12}
            >
              <Grid>
                {error.amount_dates && (
                  <Alert severity="error">{error.amount_dates}</Alert>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
              <Grid item md={11} xs={11}>
                <DatePickerField
                  disabled={vacationsData.start_date && vacationsData.end_date}
                  disableToolbar={false}
                  openTo="date"
                  errors={error && error.date}
                  onChange={(e) => {
                    handleChange("date", moment(e).format("yyyy-MM-DD"));
                  }}
                  value={vacationsData.date ? vacationsData.date : null}
                  label={t("common:date")}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
              <Grid item md={11} xs={11} lg={11}>
                <FormInputField
                  isRequired
                  errors={error && error.amount}
                  label={t("amount")}
                  name="amount"
                  type="number"
                  onChange={(e) => {
                    vacationsData.start_date = null;
                    vacationsData.end_date = null;
                    handleChange("amount", Number(e.target.value));
                  }}
                  value={vacationsData.amount}
                  id="amount"
                  autoComplete="new-password"
                  inputProps={{ min: 0 }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="center"
              item
              xs={12}
              lg={12}
              md={12}
            >
              <Grid item md={12} xs={12}>
                <Typography align="center">{t("or")}</Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
              <Grid item md={11} xs={11}>
                <DatePickerField
                  disabled={vacationsData.amount > 0}
                  disableToolbar={false}
                  errors={error && error.start_date}
                  openTo="date"
                  onChange={(e) => {
                    vacationsData.amount = 0;
                    vacationsData.date = null;
                    handleChange("start_date", moment(e).format("yyyy-MM-DD"));
                  }}
                  value={vacationsData.start_date}
                  label={t("common:startDate")}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
              <Grid item md={11} xs={11}>
                <DatePickerField
                  disabled={vacationsData.amount > 0}
                  min={moment(vacationsData.date)}
                  disableToolbar={false}
                  errors={error && error.end_date}
                  openTo="date"
                  onChange={(e) => {
                    vacationsData.amount = 0;
                    vacationsData.date = null;
                    handleChange("end_date", moment(e).format("yyyy-MM-DD"));
                  }}
                  value={vacationsData.end_date}
                  label={t("common:endDate")}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              justifyContent="center"
              item
              xs={12}
              lg={12}
              md={12}
            >
              <Grid
                item
                md={12}
                xs={12}
                lg={12}
                style={{ paddingRight: 20, paddingLeft: 20 }}
              >
                <FormInputField
                  isRequired
                  errors={error && error.amount_credit}
                  label={t("amount")}
                  name="amount"
                  type="number"
                  onChange={(e) => {
                    handleChange("amount", Number(e.target.value));
                  }}
                  value={vacationsData.amount}
                  id="amount"
                  autoComplete="new-password"
                  inputProps={{ min: 0 }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
              <Grid item md={11} xs={11}>
                <DatePickerField
                  label={t("date")}
                  name="date"
                  errors={error && error.date_credit}
                  onChange={(e) => {
                    handleChange("date", moment(e).format("yyyy-MM-DD"));
                  }}
                  value={vacationsData.date}
                />
              </Grid>
            </Grid>
          </>
        )
      ) : (
        <>
          <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
            <Grid item md={11} xs={11}>
              <TimePickerField
                label={t("startTime")}
                name={"startTime"}
                errors={error && error.start_time}
                onChange={(e) => {
                  handleChange(
                    "start_time",
                    moment(e).format("YYYY-MM-DD HH:mm"),
                  );
                }}
                value={vacationsData.start_time}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
            <Grid item md={11} xs={11}>
              <TimePickerField
                label={t("endTime")}
                name={"endTime"}
                errors={error && error.end_time}
                onChange={(e) => {
                  handleChange(
                    "end_time",
                    moment(e).format("YYYY-MM-DD HH:mm"),
                  );
                }}
                value={vacationsData.end_time}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
            <Grid item md={11} xs={11}>
              <DatePickerField
                label={t("date")}
                name="date"
                errors={error && error.short_date}
                onChange={(e) => {
                  handleChange("date", moment(e).format("yyyy-MM-DD"));
                }}
                value={vacationsData.date ? vacationsData.date : null}
              />
            </Grid>
          </Grid>
        </>
      )}
      {type !== "credit" && vacationsData.kind !== "SHORT" && (
        <Grid container justifyContent="center" item xs={12} lg={6} md={6}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            md={11}
            xs={11}
            lg={11}
          >
            <Typography variant="body1" color="primary">
              {t("common:balanceAdjustment")}
            </Typography>

            <Checkbox
              checked={vacationsData.balance_adjustment}
              onChange={(e) => {
                handleChange("balance_adjustment", e.target.checked);
              }}
              style={{ marginInline: "20px" }}
            />
          </Grid>

          <Grid item md={11} xs={11}>
            <Typography variant="subtitle2" color="textSecondary">
              {t("common:balanceAdjustmentDescription")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        item
        xs={12}
        lg={6}
        md={6}
      >
        <Grid item xs={11} lg={11} md={11}>
          <Typography color="primary">{t("common:document")}</Typography>
          <LeaveFile
            value={vacationsData.document ? vacationsData.document : null}
            setValue={(newFile) => {
              handleChange("document", newFile);
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid
        container
        direction="row"
        justifyContent="flex-end"
        item
        xs={12}
        lg={12}
        md={12}
        style={{ marginRight: "24px" }}
      >
        <Button
          color="primary"
          variant="contained"
          endIcon={<Save />}
          onClick={handleSave}
        >
          {t("common:save")}
        </Button>
      </Grid> */}
    </Grid>
  );
};
