import { Box, CircularProgress, Grid } from "@material-ui/core";

import AttendanceAndOvertimeSettings from "./AttendanceAndOvertimeSettings";
import CheckinSettings from "./CheckinSettings";
import LeaveDetails from "./LeaveDetails";
import PayrollSettings from "./PayrollSettings";
import React from "react";
import WorkingHours from "./WorkingHours";
import { connect } from "react-redux";
const CompanySettings = ({ company }) => {
  if (!company)
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={40} />
      </Box>
    );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <LeaveDetails />
      </Grid>
      <Grid item xs={12}>
        <CheckinSettings company={company} />
      </Grid>
      <Grid item xs={12}>
        <WorkingHours company={company} />
      </Grid>
      <Grid item xs={12}>
        <PayrollSettings company={company} />
      </Grid>
      <Grid item xs={12}>
        <AttendanceAndOvertimeSettings company={company} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ company }) => ({
  company: company.profile,
});

export default connect(mapStateToProps)(CompanySettings);
