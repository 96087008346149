//Material UI
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { NotesSharp } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

//Redux
import {
  fetchEmployeeDeposits,
  fetchEmployeeNextDeposit,
} from "../../../../redux/actions";
//Components
import { FormSelectInput, LineChart, PayRollTable } from "../../../common/";
import PayrollItem from "../../EmployeeProfile/Payroll/PayrollItem";
import PayrollRecurringItem from "../../EmployeeProfile/Payroll/PayrollRecurringItem";
import SectionTitle from "../SectionTitle";
import { createPayslip } from "./utils";

const EmployeeSalary = ({
  profile,
  deposits,
  nextDeposit,
  fetchEmployeeDeposits,
  fetchEmployeeNextDeposit,
}) => {
  ReactGA.pageview(window.location.pathname);
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "employeeSalary", "employeeProfile"]);
  const isEnglish = language === "en";

  const [deposit, setDeposit] = useState("All");

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (profile) {
      console.log("xxxxxxxxxxxxxx", profile);
      fetchEmployeeDeposits();
      fetchEmployeeNextDeposit({ employeeId: +profile.id });
    }
  }, [fetchEmployeeDeposits, fetchEmployeeNextDeposit, profile]);

  console.log("xxx nextDeposit xxx", nextDeposit);
  if (!nextDeposit)
    return (
      <Typography variant="h3" align="center">
        Loading
      </Typography>
    );

  let earnings = [];
  let deductions = [];
  let recurringEarnings = [];
  let recurringDeductions = [];
  let totalEarnings = 0.0;
  let totalDeductions = 0.0;

  if (deposit === "All") {
    nextDeposit.recurringSalaryModifications.forEach((modification) => {
      if (modification.amount >= 0) {
        recurringEarnings.push(modification);
        totalEarnings += modification.amount;
      } else {
        recurringDeductions.push(modification);
        totalDeductions += modification.amount;
      }
    });

    nextDeposit.salaryModifications.forEach((modification) => {
      if (modification.amount >= 0) {
        earnings.push(modification);
        totalEarnings += modification.amount;
      } else {
        deductions.push(modification);
        totalDeductions += modification.amount;
      }
    });
  } else {
    let selectedDeposit = deposits.find((_deposit) => _deposit.id === deposit);
    selectedDeposit.recurringSalaryModifications.forEach((modification) => {
      if (modification.amount >= 0) {
        earnings.push(modification);
        totalEarnings += modification.amount;
      } else {
        deductions.push(modification);
        totalDeductions += modification.amount;
      }
    });

    selectedDeposit.salaryModifications.forEach((modification) => {
      if (modification.amount >= 0) {
        earnings.push(modification);
        totalEarnings += modification.amount;
      } else {
        deductions.push(modification);
        totalDeductions += modification.amount;
      }
    });
  }

  const earningsData = earnings.map((item, i) => (
    <PayrollItem employeeId={profile.id} item={item} key={(item, +i)} />
  ));

  const recurringEarningsData = recurringEarnings.map((item) => (
    <PayrollRecurringItem employeeId={profile.id} item={item} key={item.name} />
  ));

  const deductionsData = deductions.map((item) => (
    <PayrollItem employeeId={profile.id} item={item} key={item.name} />
  ));

  const recurringDeductionsData = recurringDeductions.map((item) => (
    <PayrollRecurringItem
      item={item}
      employeeId={profile.id}
      key={item.name}
      recurring={true}
    />
  ));

  const depositsDropDown = [
    { label: t("employeeProfile:nextDeposit"), value: "All" },
    ...deposits.map((deposit) => {
      let date = new Date(deposit.month);
      return {
        label: moment(date).format("MMM-YYYY"),
        value: deposit.id,
      };
    }),
  ];

  return (
    <Box clone bgcolor="white" borderRadius={10}>
      <Grid item md={11} xs={12}>
        <Grid container justifyContent="center">
          <Grid item md={11} xs={11}>
            <SectionTitle
              title={t("employeeSalary:salaryDetails")}
              icon={<NotesSharp color="primary" />}
            />
            <Box
              clone
              bgcolor="white"
              paddingX={matchesMD ? 2 : 6}
              borderRadius={10}
            >
              <Grid container>
                <Grid item container justifyContent="flex-end" md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      createPayslip(
                        t,
                        isEnglish,
                        profile,
                        earnings,
                        deductions,
                        recurringEarnings,
                        recurringDeductions,
                        totalEarnings,
                        totalDeductions,
                        deposit.month,
                      )
                    }
                  >
                    {t("employeeSalary:payslipReport")}
                  </Button>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box clone paddingY={2}>
                    <Grid item md={2} xs={6}>
                      <Typography
                        variant="h4"
                        style={{ fontWeight: "bold" }}
                        color="textSecondary"
                      >
                        {t("employeeSalary:basicSalary")}
                      </Typography>
                      <Typography
                        variant="h4"
                        style={{ fontWeight: "bold" }}
                        color="textSecondary"
                      >
                        {t("employeeSalary:totalSalary")}
                      </Typography>
                    </Grid>
                  </Box>
                  <Grid item md={6} xs={6}>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bold" }}
                      color="textPrimary"
                    >
                      {profile.baseSalary}
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bold" }}
                      color="textPrimary"
                    >
                      {Number(profile.baseSalary) +
                        Number(totalEarnings) +
                        Number(totalDeductions)}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <FormSelectInput
                      label={t("employeeProfile:deposits")}
                      selectOptions={depositsDropDown}
                      value={deposit}
                      onChange={setDeposit}
                    />
                  </Grid>
                </Grid>
                {!!deposits.length && (
                  <Box clone paddingY={2}>
                    <Grid container alignItems="center" justifyContent="center">
                      <LineChart deposits={deposits} />
                    </Grid>
                  </Box>
                )}
                <Box clone paddingY={2}>
                  <Grid container spacing={6}>
                    <PayRollTable
                      title={t("employeeSalary:earnings")}
                      icon={<NotesSharp color="primary" />}
                      tableRow={earningsData}
                    />
                    <PayRollTable
                      title={t("employeeSalary:deductions")}
                      icon={<NotesSharp color="primary" />}
                      tableRow={deductionsData}
                    />
                  </Grid>
                </Box>
                {deposit === "All" && (
                  <Box clone paddingY={3}>
                    <Grid container spacing={3} justifyContent="center">
                      <PayRollTable
                        recurring={true}
                        title={t("employeeProfile:recurringEarnings")}
                        icon={<NotesSharp color="primary" />}
                        tableRow={recurringEarningsData}
                      />
                      <PayRollTable
                        recurring={true}
                        title={t("employeeProfile:recurringDeductions")}
                        icon={<NotesSharp color="primary" />}
                        tableRow={recurringDeductionsData}
                      />
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ employee, employees }) => ({
  profile: employee.profile,
  deposits: employee.deposits,
  nextDeposit: employees.nextDeposit,
});

const mapDispatchToPros = {
  fetchEmployeeDeposits,
  fetchEmployeeNextDeposit,
};

export default connect(mapStateToProps, mapDispatchToPros)(EmployeeSalary);
