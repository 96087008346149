// Action Types
import {
  ADD_LEAVE_CREDIT,
  ADD_LEAVE_DEBIT,
  ADD_OVERTIME_CREDIT,
  EDIT_LEAVE_REQUEST,
  EDIT_LEAVE_REQUEST_LOADING,
  EDIT_OVERTIME_REQUEST,
  EDIT_RESIGNATION_REQUEST,
  EDIT_UPDATE_REQUEST,
  GET_ERRORS,
  LEAVE_CREDIT_DEBIT_LOADING,
  REMOVE_LEAVE_CREDIT,
  REMOVE_LEAVE_DEBIT,
  REQUEST,
  REQUESTS,
  REQUEST_APPROVERS_STATUS,
  REQUEST_LOADING,
  EDIT_GENERAL_REQUEST,
} from "./types";
//Mutations
import {
  DELETE_LEAVE_CREDIT,
  DELETE_LEAVE_DEBIT,
  UPDATE_LEAVE_REQUEST,
  UPDATE_OR_CREATE_LEAVE_CREDIT,
  UPDATE_OR_CREATE_LEAVE_DEBIT,
  UPDATE_OR_CREATE_OVERTIME_CREDIT,
  UPDATE_OVERTIME_REQUEST,
  UPDATE_PROFILE_UPDATE_REQUEST,
  UPDATE_RESIGNATION_REQUEST,
  UPDATE_GENERAL_REQUEST,
  AMEND_EMPLOYEE_REQUEST,
} from "./Mutations";
//Queries
import {
  EMPLOYEE_REQUEST_DETAIL_QUERY,
  REQUESTS_QUERY,
  REQUEST_APPROVERS_STATUS_QUERY,
  REQUEST_DETAIL_QUERY,
} from "./Queries";

// Graphql
import client from "./client";
import { fetchEmployees } from "./employees";

export const fetchRequests = () => async (dispatch) => {
  try {
    const res = await client.query({
      query: REQUESTS_QUERY,
      variables: null,
    });

    const requests = res.data.requests;
    dispatch({ type: REQUESTS, payload: requests });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const requestLoading = () => async (dispatch) => {
  dispatch({ type: REQUEST_LOADING });
};

export const fetchRequestDetails = (params) => async (dispatch) => {
  try {
    dispatch(requestLoading());
    const res = await client.query({
      query: REQUEST_DETAIL_QUERY,
      variables: params,
    });

    const request = res.data.request;
    dispatch({ type: REQUEST, payload: request });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchEmployeeRequestDetails = (params) => async (dispatch) => {
  dispatch({ type: REQUEST, payload: null });
  try {
    dispatch(requestLoading());
    const res = await client.query({
      query: EMPLOYEE_REQUEST_DETAIL_QUERY,
      variables: params,
    });

    const request = res.data.request;
    dispatch({ type: REQUEST, payload: request });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateLeaveRequestLoading = (status) => async (dispatch) => {
  dispatch({ type: EDIT_LEAVE_REQUEST_LOADING, payload: status });
};

export const updateLeaveRequest = (data, closeModal) => async (dispatch) => {
  dispatch(updateLeaveRequestLoading(true));
  try {
    const res = await client.mutate({
      mutation: UPDATE_LEAVE_REQUEST,
      variables: data,
    });
    const leaveRequest = res.data.updateLeaveRequest.leaveRequest;

    dispatch({ type: EDIT_LEAVE_REQUEST, payload: leaveRequest });
    if (closeModal) closeModal();
  } catch (error) {
    dispatch(updateLeaveRequestLoading(false));
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const amendLeaveRequest = (data, closeModal) => async (dispatch) => {
  dispatch(updateLeaveRequestLoading(true));
  try {
    const res = await client.mutate({
      mutation: AMEND_EMPLOYEE_REQUEST,
      variables: data,
    });
    const leaveRequest = res.data.employeeAmendRequest.leaveRequest;
    dispatch({ type: EDIT_LEAVE_REQUEST, payload: leaveRequest });
    if (closeModal) closeModal();
  } catch (error) {
    dispatch(updateLeaveRequestLoading(false));
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateOvertimeRequest = (data, closeModal) => async (dispatch) => {
  try {
    let res = await client.mutate({
      mutation: UPDATE_OVERTIME_REQUEST,
      variables: data,
    });
    const overtimeRequest = res.data.updateOvertimeRequest.overtimeRequest;

    dispatch({ type: EDIT_OVERTIME_REQUEST, payload: overtimeRequest });
    if (closeModal) closeModal();
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateOrCreateOvertimeCredit =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_OR_CREATE_OVERTIME_CREDIT,
        variables: data,
      });

      const overtimeCredit =
        res.data.updateOrCreateOvertimeCredit.overtimeCredit;

      dispatch({ type: ADD_OVERTIME_CREDIT, payload: overtimeCredit });

      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateProfileUpdateRequest =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_PROFILE_UPDATE_REQUEST,
        variables: data,
      });

      const updateRequest =
        res.data.updateProfileUpdateRequest.updateProfileRequest;
      dispatch({ type: EDIT_UPDATE_REQUEST, payload: updateRequest });

      dispatch(fetchEmployees());
      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateResignationRequest =
  (data, closeModal) => async (dispatch) => {
    try {
      const res = await client.mutate({
        mutation: UPDATE_RESIGNATION_REQUEST,
        variables: data,
      });
      const resignationRequest =
        res.data.updateResignationRequest.resignationRequest;
      dispatch({ type: EDIT_RESIGNATION_REQUEST, payload: resignationRequest });
      if (closeModal) closeModal();
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const updateGeneralRequest = (data, closeModal) => async (dispatch) => {
  try {
    const res = await client.mutate({
      mutation: UPDATE_GENERAL_REQUEST,
      variables: data,
    });
    const generalRequest = res.data.updateGeneralRequest.generalRequest;
    dispatch({ type: EDIT_GENERAL_REQUEST, payload: generalRequest });
    if (closeModal) closeModal();
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const leaveCreditDebitLoading = (status) => async (dispatch) => {
  dispatch({
    type: LEAVE_CREDIT_DEBIT_LOADING,
    payload: status,
  });
};

export const updateOrCreateLeaveCredit =
  (data, closeModal) => async (dispatch) => {
    dispatch(leaveCreditDebitLoading(true));
    try {
      const res = await client.mutate({
        mutation: UPDATE_OR_CREATE_LEAVE_CREDIT,
        variables: data,
      });

      const newLeaveCredit = res.data.updateOrCreateLeaveCredit.leaveCredit;

      dispatch({
        type: ADD_LEAVE_CREDIT,
        payload: newLeaveCredit,
      });

      if (closeModal) closeModal();
    } catch (error) {
      dispatch(leaveCreditDebitLoading(false));
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const deleteLeaveCredit = (data, closeModal) => async (dispatch) => {
  dispatch(leaveCreditDebitLoading(true));
  try {
    const res = await client.mutate({
      mutation: DELETE_LEAVE_CREDIT,
      variables: data,
    });

    const status = res.data.deleteLeaveCredit.status;

    if (status) {
      dispatch({
        type: REMOVE_LEAVE_CREDIT,
        payload: data,
      });
    }

    if (closeModal) closeModal();
  } catch (error) {
    dispatch(leaveCreditDebitLoading(false));
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const updateOrCreateLeaveDebit =
  (data, closeModal) => async (dispatch) => {
    dispatch(leaveCreditDebitLoading(true));
    try {
      const res = await client.mutate({
        mutation: UPDATE_OR_CREATE_LEAVE_DEBIT,
        variables: data,
      });

      const newLeaveDebit = res.data.updateOrCreateLeaveDebit.leaveDebit;

      dispatch({
        type: ADD_LEAVE_DEBIT,
        payload: newLeaveDebit,
      });

      if (closeModal) closeModal();
    } catch (error) {
      dispatch(leaveCreditDebitLoading(false));
      if (error.response) {
        dispatch({
          type: GET_ERRORS,
          errors: error.response.data,
        });
      } else {
        console.error(error);
      }
    }
  };

export const deleteLeaveDebit = (data, closeModal) => async (dispatch) => {
  dispatch(leaveCreditDebitLoading(true));
  try {
    const res = await client.mutate({
      mutation: DELETE_LEAVE_DEBIT,
      variables: data,
    });

    const status = res.data.deleteLeaveDebit.status;

    if (status) {
      dispatch({
        type: REMOVE_LEAVE_DEBIT,
        payload: data,
      });
    }

    if (closeModal) closeModal();
  } catch (error) {
    dispatch(leaveCreditDebitLoading(false));
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};

export const fetchRequestApproversStatus = (id) => async (dispatch) => {
  try {
    const res = await client.query({
      query: REQUEST_APPROVERS_STATUS_QUERY,
      variables: id,
    });

    const requestApproversStatus = res.data.requestApproversStatus;
    dispatch({
      type: REQUEST_APPROVERS_STATUS,
      payload: requestApproversStatus,
    });
  } catch (error) {
    if (error.response) {
      dispatch({
        type: GET_ERRORS,
        errors: error.response.data,
      });
    } else {
      console.error(error);
    }
  }
};
