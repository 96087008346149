import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  createCompanySchedule,
  fetchCompany,
} from "../../../../../../redux/actions";

import ShiftsForm from "./ShiftsForm";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";

const WorkingHours = ({
  createCompanySchedule,
  fetchCompany,
  companySchedule,
  profileLoading,
}) => {
  const { t } = useTranslation(["common", "companyProfile"]);
  const [alert, setAlert] = useState(false);
  const getCompanyScheduleState = () => {
    let modifiedCompSched = companySchedule?.map((schedule) => {
      return {
        shifts: schedule.shifts.map((shift) => ({
          startTime: shift.startTime,
          endTime: shift.endTime,
        })),
        days: schedule.days.map((day) => day.number),
      };
    });

    return companySchedule && companySchedule.length > 0
      ? modifiedCompSched
      : companySchedule;
  };

  const [companyScheduleState, setCompanyScheduleState] = useState(
    getCompanyScheduleState(),
  );

  const createSchedule = () => {
    companyScheduleState.forEach((schedule) => {
      if (schedule.days.length === 0) {
        setAlert(true);
      } else {
        setAlert(false);
        createCompanySchedule({ schedules: companyScheduleState });
      }
    });
    // setLabels(getLabels());
  };

  useEffect(() => {
    fetchCompany();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCompanySchedule]);

  if (companySchedule?.length > 0 && companyScheduleState?.length === 0) {
    const schedule = getCompanyScheduleState();
    setCompanyScheduleState(schedule);
  }

  return (
    <Card>
      {profileLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={40} />
        </Box>
      ) : (
        <>
          <CardHeader title={t("companyProfile:workingHours")} />
          <Box minHeight="30vh" maxHeight="50vh" margin={4}>
            <form>
              {alert && (
                <Alert severity="error">Please select Schedule days !</Alert>
              )}
              <ShiftsForm
                schedulesState={companyScheduleState}
                setSchedulesState={setCompanyScheduleState}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCompanyScheduleState([
                      ...companyScheduleState,
                      {
                        days: [],
                        shifts: [{ startTime: "", endTime: "" }],
                      },
                    ]);
                  }}
                  style={{
                    textTransform: "none",
                  }}
                >
                  {t("addSchedule")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => createSchedule()}
                >
                  {t("save")}
                </Button>
              </Box>
            </form>
          </Box>
        </>
      )}
    </Card>
  );
};

const mapStateToProps = ({ company }) => ({
  companySchedule: company.schedules,
  profileLoading: company.profileLoading,
});

const mapDispatchToProps = {
  createCompanySchedule,
  fetchCompany,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkingHours);
