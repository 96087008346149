//Material UI
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableSortLabel,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React, { useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  LoadingSpinnerComponent,
  PageHeader,
  TablePagination,
} from "../../../redesign/components/common";
import { paths } from "../../../redesign/utils";
//Actions
import { fetchRequests } from "../../../redux/actions";
import { CustomTableCell, CustomTableRow, SearchInput } from "../../common/";
import AutocompleteInput from "../../common/AutocompleteInput";
import { requestsKind, requestsStatus } from "../../common/DropDownsData";
//Components
import RequestItem from "./RequestItem";
//Data & Utils
import { filterRequests } from "./utils";

const Requests = ({
  departments,
  requests,
  fetchRequests,
  positions,
  requestsLoading,
}) => {
  ReactGA.pageview(window.location.pathname);
  const {
    t,
    i18n: { language },
  } = useTranslation(["common", "requests", "breadcrumbs"]);
  const isEnglish = language === "en";
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const pendingRequests = requests.filter((req) => req.status === "PENDING");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(["employeeName"]);

  const [department, setDepartment] = useState([]);

  const _positions = () => {
    let foundDepartments = departments.filter((dep) =>
      department.some((dep1) => dep1.value === dep.id),
    );
    if (department.length) {
      let selectedDepartmentsPositions = [];
      foundDepartments.forEach(
        (dep) =>
          (selectedDepartmentsPositions = [
            ...selectedDepartmentsPositions,
            ...dep.positions,
          ]),
      );
      return selectedDepartmentsPositions;
    }
    return [...positions];
  };

  const [position, setPosition] = useState([]);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState([]);
  const [kind, setKind] = useState([]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  // get query params
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  console.log("urlParams", urlParams.get("status"));
  console.log("urlParams", urlParams.get("kind"));
  console.log("status", status);
  console.log("kind", kind);
  useEffect(() => {
    if (urlParams.get("status")) {
      const queryStatus = requestsStatus.find(
        (stat) => stat.value === urlParams.get("status").toUpperCase(),
      );
      setStatus([
        {
          label: isEnglish ? queryStatus.name : queryStatus.nameAr,
          value: queryStatus.value,
        },
      ]);
    }
    if (urlParams.get("kind")) {
      const queryKind = requestsKind.find(
        (kind) => kind.value === urlParams.get("kind").toUpperCase(),
      );
      setKind([
        {
          label: isEnglish ? queryKind.name : queryKind.nameAr,
          value: queryKind.value,
        },
      ]);
    }
  }, [urlParams, isEnglish]);

  function descendingComparator(a, b, orderBy) {
    switch (orderBy) {
      case "requestId":
        if (b.id < a.id) {
          return -1;
        }
        if (b.id > a.id) {
          return 1;
        }
        return 0;
      case "employeeName":
        const nameB = b.employee.user
          ? b.employee.user?.fullName
          : b.employee?.fullName;
        const nameA = a.employee.user
          ? a.employee.user?.fullName
          : a.employee?.fullName;
        if (nameB < nameA) {
          return -1;
        }
        if (nameB > nameA) {
          return 1;
        }
        return 0;
      case "department":
        if (
          b.employee.department.name < a.employee.department.name ||
          b.employee.department.nameAr < a.employee.department.nameAr
        ) {
          return -1;
        }
        if (
          b.employee.department.name > a.employee.department.name ||
          b.employee.department.nameAr > a.employee.department.nameAr
        ) {
          return 1;
        }
        return 0;
      case "position":
        if (
          b.employee.position.name < a.employee.position.name ||
          b.employee.position.nameAr < a.employee.position.nameAr
        ) {
          return -1;
        }
        if (
          b.employee.position.name > a.employee.position.name ||
          b.employee.position.nameAr > a.employee.position.nameAr
        ) {
          return 1;
        }
        return 0;
      case "status":
        if (b.status < a.status) {
          return -1;
        }
        if (b.status > a.status) {
          return 1;
        }
        return 0;

      case "dateRequested":
        if (new Date(b.createdOn) < new Date(a.createdOn)) {
          return -1;
        }
        if (new Date(b.createdOn) > new Date(a.createdOn)) {
          return 1;
        }
        return 0;

      case "requestType":
        if (b.kind < a.kind) {
          return -1;
        }
        if (b.kind > a.kind) {
          return 1;
        }
        return 0;

      default:
        return orderBy;
    }
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: "requestId", label: t("common:requestID") },
    { id: "employeeName", label: t("common:employeeName") },
    { id: "department", label: t("common:department") },
    { id: "position", label: t("common:position") },
    { id: "status", label: t("common:status") },
    { id: "dateRequested", label: t("common:dateRequested") },
    { id: "requestType", label: t("common:requestType") },
  ];

  function EnhancedTableHead(props) {
    const {
      order,
      orderBy,

      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      setOrderBy(property);
      onRequestSort(event, property);
    };

    const useStyles = makeStyles(() => ({
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    }));

    const classes = useStyles();

    return (
      <TableHead>
        <CustomTableRow>
          {headCells.map((headCell) => (
            <CustomTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </CustomTableCell>
          ))}
        </CustomTableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const employeesList = filterRequests(
    requests,
    department,
    position,
    status,
    kind,
    query,
  );

  const listOfEmployees = stableSort(
    employeesList,
    getComparator(order, orderBy),
  )
    .filter((request) => !request.employee.archived)
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((request) => (
      <RequestItem
        isEnglish={isEnglish}
        request={request}
        requestsStatus={requestsStatus}
        requestsKind={requestsKind}
        key={`${request.id}-${request.kind}`}
      />
    ));

  const navigationPaths = [
    {
      name: t(`breadcrumbs:${paths.dashboard.name}`),
      link: paths.dashboard.link,
    },
    {
      name: t(`breadcrumbs:${paths.requests.name}`),
      isLast: true,
    },
  ];

  return (
    <Grid container>
      <Grid item md={11} xs={12}>
        <Grid
          container
          justifyContent={matchesMD ? "center" : "flex-start"}
          spacing={4}
        >
          <Grid item md={12} xs={9}>
            <PageHeader
              paths={navigationPaths}
              title={t(`breadcrumbs:${paths.requests.header}`)}
            />
          </Grid>
          <Grid item md={12} xs={9}>
            <Box clone paddingY={1}>
              <Grid container spacing={2}>
                <Grid item md={2} xs={12}>
                  <AutocompleteInput
                    value={department}
                    options={departments.map((dep) => ({
                      label: isEnglish ? dep.name : dep.nameAr,
                      value: dep.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setDepartment(e)}
                    label={t("common:department")}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <AutocompleteInput
                    value={position}
                    options={_positions().map((pos) => ({
                      label: isEnglish ? pos.name : pos.nameAr,
                      value: pos.id,
                    }))}
                    multiple={true}
                    onChange={(e) => setPosition(e)}
                    label={t("common:position")}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <AutocompleteInput
                    value={status}
                    options={requestsStatus.map((stat) => ({
                      label: isEnglish ? stat.name : stat.nameAr,
                      value: stat.value,
                    }))}
                    multiple={true}
                    onChange={(e) => setStatus(e)}
                    label={t("common:status")}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <AutocompleteInput
                    value={kind}
                    options={requestsKind.map((kind) => ({
                      label: isEnglish ? kind.name : kind.nameAr,
                      value: kind.value,
                    }))}
                    multiple={true}
                    onChange={(e) => setKind(e)}
                    label={t("common:requestType")}
                  />
                </Grid>

                <Grid item md={3} xs={12}>
                  <SearchInput
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={t("common:search")}
                    position="start"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={12} xs={10}>
            <Box clone bgcolor="white" borderRadius={15}>
              <TableContainer>
                {requestsLoading ? (
                  <LoadingSpinnerComponent />
                ) : (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      paddingX={2}
                      paddingY={1}
                    >
                      <Typography variant="subtitle2" color="primary">
                        {`${t("requests:PENDING")}: ${pendingRequests.length}`}
                      </Typography>
                    </Box>
                    <Table>
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>{listOfEmployees}</TableBody>
                    </Table>
                  </>
                )}
              </TableContainer>
            </Box>

            <TablePagination
              count={requests.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ company, requests }) => ({
  departments: company.departments,
  positions: company.positions,
  requests: requests.requests,
  requestsLoading: requests.requestsLoading,
});

const mapDispatchToProps = {
  fetchRequests,
};

export default connect(mapStateToProps, mapDispatchToProps)(Requests);
