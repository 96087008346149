//Material UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Add,
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  NotesSharp,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useTheme } from "@material-ui/styles";
//Moment
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
//Translation
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";

import { monthsEmployed } from "../../../../helpers/monthsEmployed";
import {
  createLeaveRequest,
  fetchEmployeeLeaveSalary,
} from "../../../../redux/actions";
//Components
import {
  CompanyStructureModal,
  CustomTableCell,
  CustomTableRow,
  DatePickerField,
  DonutChart,
  FormInputField,
  FormSelectInput,
  SectionTitle,
} from "../../../common";
import { leavesKind, requestsStatus } from "../../../common/DropDownsData";
import LeaveFile from "../../../common/LeaveFile";
import RequestsTable from "../../../common/RequestsTable";
import TimePickerField from "../../../common/TimePickerField";

const EmployeeVacation = ({
  profile,
  user,
  employeeLeaveSalary,
  createLeaveRequest,
  addRequestLoading,
}) => {
  // Need to display this value

  const dispatch = useDispatch();
  ReactGA.pageview(window.location.pathname);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("before");

  const [leave, setLeave] = useState({
    date: moment(new Date()).format("yyyy-MM-DD"),
    kind: "ANNUAL",
    reason: "",
    endDate: moment(new Date()).format("yyyy-MM-DD"),
    document: "",
    startTime: moment(new Date()),
    endTime: moment(new Date()),
    requestSalaryBeforeLeave: value === "before" ? true : false,
  });

  const [error, setError] = useState("");

  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const isEnglish = language === "en";
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(
      fetchEmployeeLeaveSalary({
        startDate: leave.date,
        endDate: leave.endDate,
      }),
    );
  }, [
    dispatch,
    leave.kind,
    leave.date,
    leave.endDate,
    leave.requestSalaryBeforeLeave,
  ]);

  useEffect(() => {
    const reqValue = value === "before" ? true : false;

    setLeave({ ...leave, requestSalaryBeforeLeave: reqValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const companyVacationTypes = useSelector(
    (state) => state.employee.vacationTypes,
  );

  if (!profile) return <></>;

  const availableBalance = profile.leaveBalance.annualBalance;

  const vacationBalances = Object.keys(profile.leaveBalance)
    .filter(
      (balance) =>
        companyVacationTypes.includes(
          balance.split("Balance")[0].toUpperCase(),
        ) && balance !== "annualBalanceUncapped",
    )
    .map((balance) => (
      <CustomTableRow key={"balance" + balance}>
        <CustomTableCell>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align={isEnglish ? "left" : "right"}
          >
            {t(`common:${balance.split("Balance")[0].toLowerCase()}`)}
          </Typography>
        </CustomTableCell>
        <CustomTableCell>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align={isEnglish ? "left" : "right"}
          >
            {profile.leaveBalance[balance]}
          </Typography>
        </CustomTableCell>
      </CustomTableRow>
    ));

  let usedBalance = 0;

  profile.leaveBalance.leavedebits.forEach((debit) => {
    if (debit.isCounted) usedBalance += debit.amount;
  });

  const headerLabels = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:amount"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:createdOn"),
    t("common:status"),
    t("common:lastModified"),
    t("common:modifiedBy"),
  ];

  let vacationRequests = profile.requests
    .filter((request) => request.leaveRequest)
    .map((request) => {
      const kind = () => {
        let kindObj = leavesKind.find(
          (kind) => kind.value === request.leaveRequest.kind,
        );
        if (kindObj) {
          if (isEnglish) return kindObj.name;
          return kindObj.nameAr;
        }
        return "";
      };
      const status = () => {
        let statusObj = requestsStatus.find(
          (status) => status.value === request.status,
        );
        if (isEnglish) return statusObj.name;
        return statusObj.nameAr;
      };
      return {
        id: request.id,
        reason: request.leaveRequest.reason,
        kind: kind(),
        amount: request.leaveRequest.amount,
        startDate: moment(new Date(request.leaveRequest.date)).format(
          "DD-MM-yyyy",
        ),
        endDate: moment(new Date(request.leaveRequest.endDate)).format(
          "DD-MM-yyyy",
        ),
        createdOn: moment(new Date(request.createdOn)).format(
          "DD-MM-yyyy hh:mm a",
        ),
        status: status(),
        lastModified: moment(new Date(request.lastModified)).format(
          "DD-MM-yyyy hh:mm a",
        ),
        lastModifiedBy: request.lastModifiedBy?.fullName,
      };
    });

  const leaveCreditLabels = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:amount"),
  ];
  const leaveDebitLabels = [
    t("common:id"),
    t("common:reason"),
    t("common:kind"),
    t("common:startDate"),
    t("common:endDate"),
    t("common:amount"),
  ];

  let leaveCredits = profile.leaveBalance.leavecredits.map((leave) => {
    return {
      id: leave.id,
      reason: leave.reason,
      kind: leave.kind,
      amount: leave.amount,
    };
  });

  let leaveDebits = profile.leaveBalance.leavedebits
    .filter((leave) => leave.isCounted)
    .map((leave) => {
      console.log(leave);
      return {
        id: leave.id,
        reason: leave.reason,
        kind: leave.kind,
        date: moment(leave.date).format("DD-MM-yyyy"),
        endDate: moment(leave.endDate).format("DD-MM-yyyy"),
        amount: leave.amount,
      };
    });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getLeaveSalary = () => {
    switch (leave.kind) {
      case "ANNUAL":
        return employeeLeaveSalary?.annualBalance;

      case "CASUAL":
        return employeeLeaveSalary?.casualBalance;

      case "SICK":
        return employeeLeaveSalary?.sickBalance;

      case "SHORT":
        return employeeLeaveSalary?.shortLeaveBalance;

      case "UNPAID":
        return 0;

      default:
        break;
    }
  };

  const getAvailableBalance = () => {
    switch (leave.kind) {
      case "ANNUAL":
        return availableBalance;

      case "CASUAL":
        return profile.leaveBalance.casualBalance;

      case "SICK":
        return profile.leaveBalance.sickBalance;

      case "OFFSET":
        return profile.leaveBalance.offsetBalance;

      default:
        return "-";
    }
  };

  const filteredLeaveTypes = () => {
    const totalMonths = monthsEmployed(profile.employmentDate, leave.date);
    const modifiedLeaveTypes = leavesKind.filter(
      (leave) => leave.value !== "SICK",
    );

    if (profile.employmentStatus === "FULL_TIME" && totalMonths < 6) {
      if (leave.kind === "SICK") {
        setLeave((leave) => ({ ...leave, kind: "UNPAID" }));
      }
      return modifiedLeaveTypes;
    } else if (profile.employmentStatus === "TRIAL_PERIOD" && totalMonths < 3) {
      if (leave.kind === "SICK") {
        setLeave((leave) => ({ ...leave, kind: "UNPAID" }));
      }

      return modifiedLeaveTypes;
    } else {
      return leavesKind;
    }
  };

  const closeModal = () => {
    setOpen(false);
    setError("");
    setValue("before");
    setLeave({
      date: moment(new Date()).format("yyyy-MM-DD"),
      kind: "ANNUAL",
      reason: "",
      endDate: moment(new Date()).format("yyyy-MM-DD"),
      startTime: moment(new Date()),
      endTime: moment(new Date()),
      requestSalaryBeforeLeave: value === "before" ? true : false,
    });
  };

  const onSubmit = () => {
    if (leave.reason === "") {
      setError(t("reasonError"));
    } else {
      if (leave.kind !== "SHORT") {
        const { startTime, endTime, ...rest } = leave;
        createLeaveRequest(rest, () => closeModal());
      } else {
        const { startTime, endTime, requestSalaryBeforeLeave, ...rest } = leave;
        if (moment(endTime).isBefore(moment(startTime))) {
          setError(t("timeError"));
        } else {
          createLeaveRequest(
            {
              startTime: startTime.format("HH:mm:ss"),
              endTime: endTime.format("HH:mm:ss"),
              ...rest,
            },
            () => closeModal(),
          );
        }
      }
    }
  };

  return (
    <>
      <Grid item md={11} xs={12}>
        <Box
          clone
          bgcolor="white"
          minHeight={"500px"}
          paddingX={matchesMD ? 2 : 6}
          marginBottom={3}
          paddingY={3}
          borderRadius={10}
        >
          <Grid container justifyContent="center">
            <Grid item md={12} xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <SectionTitle
                  title={t("vacationRequest")}
                  icon={<NotesSharp color="primary" />}
                />
                {user.isEmployee && (
                  <Button
                    style={{ width: "195px" }}
                    variant="text"
                    color="primary"
                    endIcon={<Add />}
                    onClick={() => setOpen(true)}
                  >
                    {t("leaveRequest")}
                  </Button>
                )}
              </Box>
            </Grid>
            <Box
              display={matchesXS ? undefined : "flex"}
              flexDirection={matchesXS ? undefined : "row"}
              justifyContent="center"
            >
              <Grid container>
                <Grid item md={6} xs={12}>
                  <DonutChart
                    radius={20}
                    label={({ dataEntry }) =>
                      `${dataEntry.title}  (${dataEntry.value} ${t("days")})`
                    }
                    labels={[
                      {
                        label: t("availableCredit"),
                        value: availableBalance,
                      },
                      {
                        label: t("usedCredit"),
                        value: usedBalance,
                      },
                    ]}
                  />
                </Grid>
                <Box
                  clone
                  paddingLeft={matchesXS ? undefined : isEnglish ? 15 : 0}
                  paddingRight={matchesXS ? undefined : isEnglish ? 0 : 15}
                  paddingY={matchesXS ? undefined : 10}
                  maxWidth={"100%"}
                >
                  <Grid item md={6} xs={12}>
                    <TableContainer>
                      <Table>
                        <TableBody>{vacationBalances}</TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Box>
              </Grid>
            </Box>
            <Grid item md={12} xs={12}>
              <RequestsTable
                data={vacationRequests}
                labels={headerLabels}
                toRow={"/request"}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SectionTitle
                title={t("vacationCredits")}
                icon={<NotesSharp color="primary" />}
              />
              <RequestsTable data={leaveCredits} labels={leaveCreditLabels} />
            </Grid>
            <Grid item md={6} xs={12}>
              <SectionTitle
                title={t("vacationDebits")}
                icon={<NotesSharp color="primary" />}
              />
              <RequestsTable data={leaveDebits} labels={leaveDebitLabels} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <CompanyStructureModal
        open={open}
        onClose={closeModal}
        fadeIn={open}
        title={t("leaveRequest")}
      >
        <Box height={"auto"} maxHeight={"90vh"} margin={4}>
          {addRequestLoading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress size={40} />
            </Box>
          ) : (
            <>
              <Grid container spacing={3} justifyContent="center">
                {error && (
                  <Grid item md={8} xs={12}>
                    <Alert onClick={() => setError("")} severity="error">
                      {error}
                    </Alert>
                  </Grid>
                )}
                <Grid item md={6} xs={6}>
                  <FormInputField
                    isRequired
                    label={t("reason")}
                    name="reason"
                    type="text"
                    onChange={(e) => {
                      setLeave({ ...leave, reason: e.target.value });
                    }}
                    value={leave.reason}
                    id="reason"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item md={6} xs={6}>
                  <FormSelectInput
                    isRequired
                    label={t("leaveKind")}
                    name="kind"
                    selectOptions={filteredLeaveTypes()
                      .filter((item) =>
                        companyVacationTypes.includes(item.value),
                      )
                      .map((kind) => ({
                        label: isEnglish ? kind.name : kind.nameAr,
                        value: kind.value,
                      }))}
                    onChange={(e) => setLeave({ ...leave, kind: e })}
                    value={leave.kind}
                  />
                </Grid>

                {leave.kind === "SHORT" ? (
                  <>
                    <Grid item md={12} xs={6}>
                      <DatePickerField
                        label={t("date")}
                        name="date"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                            endDate: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.date}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TimePickerField
                        label={t("startTime")}
                        name="startTime"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            startTime: moment(e),
                          })
                        }
                        value={leave.startTime}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <TimePickerField
                        label={t("endTime")}
                        name="endTime"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            endTime: moment(e),
                          })
                        }
                        value={leave.endTime}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item md={6} xs={12}>
                      {/* <DateRangeInput
                      onChange={(selectedDates) =>
                        setLeave({
                          ...leave,
                          date: moment(selectedDates[0]).format("yyyy-MM-DD"),
                          endDate: moment(selectedDates[1]).format(
                            "yyyy-MM-DD",
                          ),
                        })
                      }
                    /> */}
                      <DatePickerField
                        label={t("date")}
                        name="date"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            date: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.date}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <DatePickerField
                        label={t("endDate")}
                        name="endDate"
                        onChange={(e) =>
                          setLeave({
                            ...leave,
                            endDate: moment(e).format("yyyy-MM-DD"),
                          })
                        }
                        value={leave.endDate}
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={12}>
                  <LeaveFile
                    value={leave.document}
                    setValue={(newDocument) =>
                      setLeave({ ...leave, document: newDocument })
                    }
                  />
                </Grid>
                <Grid item container>
                  <Grid item md={12} style={{ paddingTop: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">{`${t(
                      "common:totalCountRequested",
                    )}: ${
                      moment(leave.endDate, "yyyy-MM-DD")?.diff(
                        moment(leave.date, "yyyy-MM-DD"),
                        "days",
                      ) + 1
                    }`}</Typography>
                  </Grid>

                  <Grid item md={12} style={{ paddingTop: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {" "}
                      {`${t("common:estimatedSalaryAfterLeave")}: ${parseFloat(
                        getLeaveSalary(),
                      ).toFixed(3)} KWD`}
                    </Typography>
                  </Grid>

                  <Grid item md={12} style={{ paddingTop: 1 }}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {" "}
                      {`${t(
                        "common:totalNumberInBalance",
                      )}:  ${getAvailableBalance()}`}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="salaryRequest"
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={"before"}
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckBoxOutlined />}
                              icon={<CheckBoxOutlineBlankOutlined />}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              color="primary"
                              style={{ fontWeight: "bold" }}
                            >
                              {t("common:requestSalaryBeforeLeave")}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value={"after"}
                          control={
                            <Radio
                              size="small"
                              checkedIcon={<CheckBoxOutlined />}
                              icon={<CheckBoxOutlineBlankOutlined />}
                            />
                          }
                          label={
                            <Typography
                              variant="subtitle2"
                              color="primary"
                              style={{ fontWeight: "bold" }}
                            >
                              {t("common:requestSalaryAfterLeave")}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Box clone marginX={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                      >
                        <Typography>{t("confirm")}</Typography>
                      </Button>
                    </Box>
                    <Box clone marginX={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpen(false)}
                      >
                        <Typography>{t("cancel")}</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </CompanyStructureModal>
    </>
  );
};

const mapStateToProps = ({ employee, auth }) => ({
  profile: employee.profile,
  user: auth.user,
  employeeLeaveSalary: employee.leaveSalary,
  addRequestLoading: employee.addRequestLoading,
});

const mapDispatchToProps = {
  createLeaveRequest,
  fetchEmployeeLeaveSalary,
};
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeVacation);
